import { axiosGet, axiosPost } from "@/api/request"
import apis from "@/api/apis"

export function handleGetPayType(data) {
    return axiosGet(apis.getPayType, data)
}

// 获取我的余额
export function handleGetMyBalance(data) {
    return axiosGet(apis.myBalance, data)
}