<template>
    <div class="pay_wrap flex_start">
        <div class="lf_order">
            <div class="success_title flex_between_center">
                <div class="suc_lf flex_start_center">{{orderData && orderData.order_info.trip_type==1?'实时':'预约'}} {{orderData.order_info.add_time | timestampToTime}}
                    <el-tag effect="dark" size="mini" v-if="orderData && orderData.order_info.order_status==20">已接单</el-tag>
                    <el-tag effect="dark" size="mini" v-else-if="orderData && orderData.order_info.order_status==30">运输中</el-tag>
                    <el-tag effect="dark" size="mini" v-else-if="orderData && orderData.order_info.order_status==31">待签收</el-tag>
                    <el-tag effect="dark" size="mini" v-else-if="orderData && orderData.order_info.order_status==40">已完成</el-tag>
                    <el-tag effect="dark" size="mini" v-else-if="orderData && orderData.order_info.order_status==51">已取消</el-tag>
                </div>
                <div class="suc_rg">费用：<span style="color:#f54123;font-size:22px;">￥{{orderData && orderData.estimate_amount}}</span>
                </div>
            </div>
            <div class="success_content flex_start_center">
                <div class="order_mileage">
                    <div class="mileage_lf flex_start">
                        <div class="start_mileage flex_start">
                            <div class="start_icon">装</div>
                            <div class="column_start" style="flex: 1;">
                                <span style="line-height: 1.2;font-size: 14px;">天河软件园华景园区</span>
                                <span style="line-height: 1.2;font-size: 14px;color: #aaa;">胡先生(18520987432)</span>
                            </div>
                        </div>
                        <img src="@assets/imgs/u912.png" class="right_icon" alt="">
                        <div class="end_mileage flex_start">
                            <div class="end_icon">卸</div>
                            <div class="column_start" style="flex: 1;">
                                <span style="line-height: 1.2;font-size: 14px;">广州市南沙港港口码头</span>
                                <span style="line-height: 1.2;font-size: 14px;color: #aaa;">王先生(18348937024)</span>
                            </div>
                        </div>
                    </div>
                    <div class="mileage_num">小货车 运输里程：{{costData.distance}}公里</div>
                    <div class="flex_between_center margin_top">
                        <span class="lf_label">运费总价：</span>
                        <span class="rg_value">￥{{costData.estimate_price}}</span>
                    </div>
                    <div class="flex_between_center margin_top">
                        <span class="lf_label">代付费：</span>
                        <span class="rg_value" style="color: #f54123;">+ ￥{{priceData.other_free_total||0}}</span>
                    </div>
                    <div class="flex_between_center margin_top" v-if="costData.coupon_money>0">
                        <span class="lf_label">优惠券抵扣：</span>
                        <span class="rg_value" style="color: #f54123;">- ￥{{costData.coupon_money}}</span>
                    </div>
                    <div class="flex_between_center margin_top">
                        <span class="lf_label">订单总价：</span>
                        <span class="rg_value" style="color: #f54123;">￥{{costData.estimate_price}}</span>
                    </div>
                    <div class="order_total_price">需付款金额：<span style="color: #f54123;font-size: 24px;">￥{{costData.estimate_price}}</span></div>
                </div>
            </div>
        </div>
        <div class="rg_order column_between_start">
            <div class="pay_type_group">
                <div class="flex_between_center" style="width: 100%;margin-bottom: 20px;">
                    <label for="wx_chat" class="flex_start_center">
                        <img class="pay_icon" src="@assets/imgs/u910.png"
                            alt="">
                        <span style="font-size: 15px;color: #333;">微信支付</span>
                    </label>
                    <input type="radio" name="pay_type" id="wx_chat" value="wx_chat" v-model="pay_type_radio"></input>
                </div>
                <div class="flex_between_center" style="width: 100%;">
                    <label for="yue" class="flex_start_center">
                        <img class="pay_icon" src="@assets/imgs/u911.png"
                            alt="">
                        <span style="font-size: 15px;color: #333;">余额支付（当前余额账户：￥{{userMoney}}）</span>
                    </label>
                    <input type="radio" name="pay_type" id="yue" value="yue" v-model="pay_type_radio"></input>
                </div>
            </div>
            <el-button type="primary" class="pay_btn">支付订单</el-button>
        </div>
    </div>
</template>
<script>
import { handleGetPayType,handleGetMyBalance} from "@api/payOrder"
import { handleGetOrderPrice} from '@api/priceDetail'

export default {
    data() {
        return {
            pay_type_radio: '',
            payType:'',
            payment_id:'',
            costData:{},
            priceData:{},
            orderData:{},
            page: 1,
            limit: 10,
            type: 1, //类型:1充值2消费
            addTotal:'',
            userMoney:'',
        }
    },
    created() {
        let {order_sn = false,orderData = false} = this.$route.params;
        if(order_sn && orderData){
            this.order_sn = order_sn;
            this.orderData = orderData;
            this.onGetOrderPrice(order_sn,);
        }
        if(this.userData != null){
            this.onGethandleGetMyBalance()
        }
    },
    mounted() {
        
    },
    methods:{
        onGetPayType(){
            handleGetPayType().then(res=>{
                if(res.status == 0){
                    this.payType = res.data;
                }
            })
        },
        onGethandleGetMyBalance(){
            let datakey = {}
            let page = this.page
            let limit = this.limie
            datakey['user_sn'] = this.userData.user_sn
            datakey['type'] = this.type
            datakey['page'] = page
            datakey['limit'] = limit
            handleGetMyBalance(datakey).then(datas=>{
                if (datas.status == 0) {
                    this.userMoney = datas.data.userMoney;
                    this.addTotal = datas.data.addTotal;
                }
            }).catch(err=>{

            })
        },
        onGetOrderPrice(order_sn, back_car) {
            let datakey = {}
            datakey['order_sn'] = order_sn
            handleGetOrderPrice(datakey).then(datas=>{
                if (datas.status == 0) {
                    let costData = datas.data.order_free
                    costData.back_car = back_car
                    costData.zhuanche_money = datas.data.freight_total
                    costData.huichengche_money = datas.data.freight_total
                    costData.init_km = datas.data.freight.init_km || 0
                    costData.estimate_price = datas.data.total
                    costData.pendant_name = datas.data.pandent_name
                    costData.pendant_price = datas.data.pandent_price
                    this.costData = costData;
                    this.priceData = datas.data;
                }
            }).catch(err=>{
                console.log(err)
                this.$message.error(err.msg)
            })
        },
    },
    computed:{
        userData:function(){
            return this.$store.getters.userData || this.$storage.get('userData')
        },
    },
    filters:{
        timestampToTime: function(timestamp) {
            if (timestamp != undefined) {
                var date = getDate(timestamp * 1000)
                var Y = date.getFullYear() + '-';
                var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
                var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
                var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
                var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
                var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
                return `${Y}-${M}-${D} ${h}:${m}`;
            }
        }
    }
}
</script>
<style lang="scss">
    .pay_wrap {
        padding: 16px;
        .lf_order {
            width: 50%;
            border-radius: 10px;
            border: 1px solid #ddd;
            padding: 0 20px 20px;
            margin-right: 30px;
            height: 360px;
        }
        
        .success_content {
            padding: 20px 0;
        }
        
        .success_title {
            height: 50px;
            border-bottom: 1px dashed #ddd;
        }
        
        .suc_lf {
            font-size: 15px;
        }
        
        .order_mileage {
            flex: 1 1;
        }
        
        .mileage_lf {
            margin-bottom: 16px;
        }
        
        .start_mileage {
            margin-right: 10px;
        }
        
        .start_icon,
        .end_icon {
            width: 30px;
            height: 30px;
            border-radius: 30px;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
            font-size: 14px;
            color: #fff;
            margin-right: 20px;
        }
        
        .start_icon {
            background: #299ede;
        }
        
        .end_icon {
            background: #f54123;
        }
        
        .mileage_num {
            color: #aaa;
        }
        
        .lf_label {
            font-size: 14px;
            color: #333;
        }
        
        .rg_value {
            font-size: 14px;
            color: #333;
        }
        
        .order_total_price {
            color: #333;
            font-size: 16px;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: 20px;
        }
        
        .margin_top {
            margin-top: 20px;
        }
        
        .rg_order {
            height: 360px;
            width: 50%;
        }
        
        .pay_type_group {
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 10px;
            width: 100%;
        }
        
        .pay_type_group .el-radio-group {
            width: 100%;
        }
        
        .pay_icon {
            width: 40px;
            height: 40px;
            margin-right: 4px;
        }
        
        .pay_btn {
            align-self: flex-end;
        }
    }
    
    
</style>